<template>
  <layout :extranjero="true">
    <div class="row votosPorCandidaturaMovil" v-if="cargaTerminada">
      <div class="col-12 col-md-10 mx-auto">
        <div class="votoEnElExtranjero mb-4">
          <!-- <select class="tipoActa" v-model="tipo" @change="actualizarVista">
            <option :value="null">Todas</option>
            <option value="E">Voto Electrónico</option>
            <option value="P">Voto Postal</option>
          </select> -->

          <router-link to="/G/ENT/VPC" class="btn btn-primary mx-auto d-block btn-actas px-4 w-50">
            Regresar
          </router-link>
        </div>
        <h4>
          Gubernatura -
          <span class="primario">Voto en el Extranjero</span>
        </h4>

        <p>
          <b>
            Baja California Sur
          </b> <br/>
          Cantidad de votos de la Entidad, conforme a la
          información de las Mesas de Escrutinio y Cómputo.
        </p>

        <div class="totalVotos mb-5 votoEnElExtranjero">
          <h5 class="text-center"><b>Total de votos</b></h5>

          <h1 class="total mb-0">
            {{ resumen.dato_casilla[0].total_numero }}
          </h1>
          <p class="porcentaje mb-3">
            {{ resumen.dato_casilla[0].total_porcentaje }}%
          </p>
        </div>

        <div class="votosEnActasContabilizadas mb-5">
          <p class="titulo">
            Votos en Actas Contabilizadas
          </p>

          <p class="mb-1">Imagen procedente de:</p>

          <a
            :href="calcularUrlActaDigitalizada(votoExtranjeroXCasilla)"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button class="btn btn-primary btn-ver-acta">
              <font-awesome-icon
                :icon="['far', 'file']"
                class="mx-1"
              ></font-awesome-icon>
              <!-- {{ textoTipoActa }} -->
              Ver
            </button>
          </a>
        </div>

        <div class="row rowActas mb-5">
          <div
            class="col-12 py-3"
            v-for="(partido, index) in votoExtranjeroTarjetas.datos_votos"
            :key="index"
          >
            <div class="grilla-partidos">
              <div>
                <img :src="partido.candidatura_logo" class="mx-auto d-block">
              </div>
              <div>
                <label for="">
                  Votos <br />
                  <b>{{ partido.candidatura_votos_cantidad  }}</b>
                </label>
              </div>
              <div>
                <label for="">
                  {{ partido.candidatura_votos_porcentaje }}%
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="resumenDeLaVotacion mb-4">
          <p class="titulo">
            Resumen de la votación
          </p>

          <ResumenVotacion
            texto="Candidaturas no registradas"
            :numero="resumen.dato_casilla[0].candidaturas_no_registradas_numero"
            :porcentaje="resumen.dato_casilla[0].candidaturas_no_registradas_porcentaje"  />
          <ResumenVotacion texto="Nulos"
            :numero="resumen.dato_casilla[0].nulos_numero"
            :porcentaje="resumen.dato_casilla[0].nulos_porcentaje"  />

        </div>

        <div class="votosEnActasContabilizadas">
          <p class="titulo">
            Estadística de la casilla
          </p>

          <h5 class="font-weight-bold mb-3">
            Participación ciudadana con base en la Lista Nominal de las Actas PREP Contabilizadas
          </h5>
        </div>

        <div
          class="votosTotalesAvance mb-4"
        >
          <div class="grilla mb-2">
            <div class="leyenda">
              <p class="font-weight-bold mb-0">

              </p>
            </div>
            <div class="total">
              <h4 class="mb-0 text-right">
                {{ estadisticas.participacion_ciudadana_porcentaje }}%
              </h4>
            </div>
          </div>
          <b-progress max="100" :value="estadisticas.participacion_ciudadana_porcentaje" />
        </div>

        <div class="listaNominal mb-4">
          <h5 class="font-weight-bold mb-3">
            Lista Nominal
          </h5>

          <h1 class="text-center font-weight-bold mb-1">
            {{  estadisticas.total_lista_nominal_numero }}
          </h1>
          <p class="text-center small">
            Lista Nominal de Actas Contabilizadas
          </p>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import useCalcularUrlActaEscaneada from '@/composables/useCalcularUrlActaEscaneada';
import Layout from '@/components/Movil/layouts/Layout.vue';
import ResumenVotacion from '../componentes/ResumenVotacion.vue';

export default defineComponent({
  name: 'VotoEnElExtranjeroMovil',
  components: {
    Layout,
    ResumenVotacion,
  },
  data() {
    return {
      tipo: this.$route.params.tipo ?? null,
    };
  },
  computed: {
    cargaTerminada() {
      return this.$store.state.cargaTerminada;
    },
    votoExtranjeroTarjetas() {
      // if (this.tipo === 'E') {
      //   return this.$store.state.gVotosExtranjeroXCasilla.datos_casillas[1].datos_votos.slice(1,
      //     this.$store.state.gVotosExtranjeroXCasilla.datos_casillas[1].datos_votos.length - 3);
      // } if (this.tipo === 'P') {
      //   return this.$store.state.gVotosExtranjeroXCasilla.datos_casillas[0].datos_votos.slice(1,
      //     this.$store.state.gVotosExtranjeroXCasilla.datos_casillas[0].datos_votos.length - 3);
      // }
      return this.$store.state.gVotosExtranjeroXCasilla.datos_casillas[0];
    },
    resumen() {
      return this.$store.state.gResumenVotoExtranjero;
    },
    votoExtranjeroXCasilla() {
      if (this.tipo === 'E') {
        return this.$store.state.gVotosExtranjeroXCasilla;
      } if (this.tipo === 'P') {
        return this.$store.state.gVotosExtranjeroXCasilla;
      }
      return this.$store.state.gVotosExtranjeroXCasilla;
    },
    estadisticas() {
      if (this.tipo === 'E') { // electrónico
        return this.$store.state.gEstadisticasVotoExtranjero.datos_casillas[1];
      } if (this.tipo === 'P') { // postal
        return this.$store.state.gEstadisticasVotoExtranjero.datos_casillas[0];
      } // todos
      return this.$store.state.gEstadisticasVotoExtranjero.datos_casillas[1];
    },
    actas() {
      const votoExtranjero = this.$store.state.entidad.votosExtranjero;

      if (this.tipo === 'E') {
        return votoExtranjero.electronico;
      } if (this.tipo === 'P') {
        return votoExtranjero.postal;
      }

      return votoExtranjero.todas;
    },
    textoTipoActa() {
      if (!this.tipo) {
        return 'Acta voto total';
      }

      return this.tipo === 'E'
        ? 'Voto Electrónico'
        : 'Voto Postal';
    },
  },
  methods: {
    actualizarVista() {
      if (this.tipo) {
        this.$router.push(`/G/VE/${this.tipo}`);
      } else {
        this.$router.push('/G/VE');
      }
      window.location.reload();
    },
  },
  setup() {
    return {
      ...useCalcularUrlActaEscaneada(),
    };
  },
});
</script>

<style lang="scss" scoped>
.votoEnElExtranjero {
  .tipoActa {
    width: 100%;
    border-color: transparent;
    background-color: #D3097F;
    padding: 15px;
    margin-bottom: 15px;
    color: #FFF;
    border-radius: 15px;
  }

}
.rowActas {
  .col-12 {
    &:nth-child(odd) {
      background: #efefef;
      color: black;
    }
  }
}
.grilla-partidos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-end;

  img {
    max-height: 50px;
  }
}

.votosEnActasContabilizadas {
  button.btn-ver-acta {
    background-color: #D3097F;
    width: 100%;
    max-width: 150px;
    border-color: transparent !important;
    box-shadow: none !important;
    font-size: 18px;
  }
}

.votoEnElExtranjero {
  background-image: url('../../../assets/Fondo_Mapa.png');
  background-repeat: no-repeat;

  &:first-child {
    background-image: none;
  }
}
</style>
