<script>
import VotoEnElExtranjeroMovil from '@/components/Movil/vistas/VotoEnElExtranjero.vue'
import VotoEnElExtranjeroEscritorio from '@/components/Escritorio/vistas/VotoEnElExtranjero.vue'

export default {
  name: 'VotoEnElExtranjero',
  components: {
    VotoEnElExtranjeroMovil,
    VotoEnElExtranjeroEscritorio
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2021 - Voto En El Extranjero'
  },
  computed: {
    anchoPantalla () {
      return this.$store.state.anchoPantalla
    }
  },
  render () {
    return (
      <div>
        {
          this.anchoPantalla < 992
            ? <VotoEnElExtranjeroMovil />
            : <VotoEnElExtranjeroEscritorio />
        }
      </div>
    )
  }
}
</script>
