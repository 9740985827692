function useCalcularUrlActaEscaneada() {
  const calcularUrlActaDigitalizada = (casilla) => {
    const urlActa = casilla.candidatura_votos_cantidad ?? casilla.url_imagen_acta;
    if (urlActa === 'sin url') {
      return null;
    }

    return urlActa;
  };

  return {
    calcularUrlActaDigitalizada,
  };
}

export default useCalcularUrlActaEscaneada;
