<template>
  <div class="vista-escritorio">
    <cabecera />
    <cuerpo>
      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <h2>Gubernatura - <span><strong>Voto en el Extranjero</strong></span></h2>
            <p style="font-weight: 400;">
              El total de votos calculado y porcentaje que se muestran,
              se refieren a los votos asentados desde el Extranjero en el Acta de
              Cómputo Entidad Federativa hasta el momento. <br />
              Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos.
              No obstante, al considerar todos los decimales, suman 100%.
            </p>

            <div class="encabezado">
              <h3>
                Votos en Actas Contabilizadas
              </h3>

              <div class="grilla-estado-acta mb-4" style="align-items: flex-start;">
                <div class="acta-digitalizada">
                  <p class="small font-weight-bold">
                    Ver Acta Digitalizada de Cómputo Entidad Federativa:
                  </p>
                  <a
                    :href="calcularUrlActaDigitalizada(votoExtranjeroXCasilla)"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      class="btn btn-primary w-100"
                      style="border-radius: 10px; max-width: 150px;"
                    >
                      <div class="">
                        <font-awesome-icon
                          :icon="['far', 'file']"
                          class="fa-2x mr-2"
                        ></font-awesome-icon>
                        <span>Acta</span>
                      </div>
                    </button>
                  </a>
                </div>
                <div class="procedencia">
                  <p class="small" style="color: #4e4e4e; font-weight: 500;">
                    <b>
                      Imagen procedente de:
                    </b>
                  </p>
                  <div class="row align-items-center">
                    <div class="col-4" style="border-right: 2px solid #858585;">
                      <div class="grilla-interna proceso" style="grid-template-columns: 75px 1fr;">
                        <div>
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="fa-2x mr-2"
                          ></font-awesome-icon>
                          <font-awesome-icon
                            icon="hourglass"
                            class="fa-2x mr-2"
                          ></font-awesome-icon>
                        </div>
                        <div>
                          Acta en proceso
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div
                        class="grilla-interna terminado"
                        style="grid-template-columns: 50px 1fr;"
                      >
                        <font-awesome-icon
                          :icon="['far', 'file']"
                          class="fa-2x mr-2"
                        ></font-awesome-icon>
                        <span>
                          Acta de Cómputo <br />
                          Entidad Federativa
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- <procedencia-acta /> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <slider-votos :votos="votoExtranjeroTarjetas" />

        <div class="titulos">
          <div class="row px-3">
            <div class="col-8 mb-3 resumen-votacion">
              <h4 class="mb-2">
                Resumen de la votación
              </h4>
              <div class="grilla-suma">
                <div class="acumulados text-center">
                  <p class="font-weight-bold">
                    Votos acumulados
                    <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                    <br />
                    {{ resumen.votos_acumulados_numero }} <br />
                    <span class="porcentaje">
                      {{ resumen.votos_acumulados_porcentaje }}%
                    </span>
                  </p>
                </div>
                <div class="icono-suma">
                  <font-awesome-icon icon="plus" class="fa-2x" />
                </div>
                <div class="no-registradas text-center">
                  <p class="font-weight-bold">
                    Candidaturas no registradas <br />
                    {{ resumen.candidaturas_no_registradas_numero }} <br />
                    <span class="porcentaje">
                      {{ resumen.candidaturas_no_registradas_porcentaje }}%
                    </span>
                  </p>
                </div>
                <div class="icono-suma">
                  <font-awesome-icon icon="plus" class="fa-2x" />
                </div>
                <div class="nulos text-center">
                  <p class="font-weight-bold">
                    Nulos <br />
                    {{ resumen.nulos_numero }} <br />
                    <span class="porcentaje">
                      {{ resumen.nulos_porcentaje }}%
                    </span>
                  </p>
                </div>
                <div class="icono-suma">
                  <font-awesome-icon icon="equals" class="fa-2x" />
                </div>
                <div class="total text-right">
                  <p class="font-weight-bold">
                    Total de votos <br />
                    <big>
                      {{
                        resumen.total_numero
                      }}
                    </big> <br />
                    <span class="porcentaje">
                      {{resumen.total_porcentaje}}%
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <ParticipacionCiudadana
            :numero="estadisticas.total_lista_nominal_numero"
            :porcentaje="parseFloat(estadisticas.participacion_ciudadana_porcentaje)"
            :leyenda="'Participacion Ciudadana'" />
          </div>
        </div>
      </div>
      <div class="text-center mt-3">
        <p>
          Los resultados presentados tienen un carácter informativo y no son definitivos,
          por tanto, no tienen efectos jurídicos. <br /> Con base en la Ley Federal del
          Derecho de Autor queda prohíbida cualquier modificación al diseño de este sitio. <br />
          La modificación y/o destrucción de la información y/o contenido total o parcial de
          este sitio, es delito federal de acuerdo al Código Penal Federal.
        </p>
      </div>
    </cuerpo>
    <Compartir />
    <PieDePagina />
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
import useCalcularUrlActaEscaneada from '@/composables/useCalcularUrlActaEscaneada';
import Cabecera from '@/components/Escritorio/componentes/Cabecera';
import Cuerpo from '@/components/Escritorio/componentes/Cuerpo';
import Compartir from '@/components/Compartir.vue';
import PieDePagina from '@/components/Escritorio/componentes/PieDePagina';
import ParticipacionCiudadana from '@/components/Escritorio/componentes/ParticipacionCiudadana.vue';
import SliderVotos from '@/components/Escritorio/componentes/SliderVotos.vue';

export default defineComponent({
  name: 'VotoEnElExtranjero',
  components: {
    Cabecera,
    Cuerpo,
    Compartir,
    ParticipacionCiudadana,
    PieDePagina,
    SliderVotos,
  },
  setup(_, { root }) {
    const state = computed(() => root.$store.state);

    // eslint-disable-next-line max-len
    const votoExtranjeroTarjetas = computed(() => state.value?.gVotosExtranjeroXCasilla.datos_casillas[0].datos_votos);

    const resumen = computed(() => state.value.gResumenVotoExtranjero.dato_casilla[0]);
    // const resumen = computed(() => state.value.gResumenVotoExtranjero.datos_casillas[0]);
    const estadisticas = computed(() => state.value?.gEstadisticasVotoExtranjero.datos_casillas[1]);
    const votoExtranjeroXCasilla = computed(() => state.value?.gVotosExtranjeroXCasilla);
    const votos = computed(() => state.value.entidad.votosExtranjero);

    return {
      ...useCalcularUrlActaEscaneada(),
      votoExtranjeroTarjetas,
      resumen,
      votos,
      votoExtranjeroXCasilla,
      estadisticas,
    };
  },
});
</script>

<style lang="scss" scoped>
.grilla-estado-acta {
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 30px;
  align-items: flex-end;

  .acta-digitalizada {
    .btn {
      background-color: #D2127E !important;
      border-color: transparent;
    }
  }

  .grilla-interna {
    display: grid;
    grid-template-columns: 75px 1fr;
    align-items: center;
  }
}

.foto {
  img {
    max-width: 40px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.resumen-voto-candidatura {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  padding: 3px;
  border: 1px solid #181A2F;
  //height: 25vh;
  max-width: 150px;
  .grilla-datos-candidatura {
    margin: 10px;
    .foto > svg {
      max-width: 50px;
      margin: 0 auto;
      display: block;
      margin-bottom: 10px;
    }
  }
  .grilla-resumen {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 15px;
    margin-top:10px;
  }
}

.resumen-votacion {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 15px;

  .grilla-suma {
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    /* max-width: 900px; */
    align-items: center;
    gap: 30px;

    .acumulados, .nulos, .no-registradas, .total {
      p {
        color: #34344E;
      }
    }

    .total {
      big {
        font-size: 2rem;
      }
      big, .porcentaje  {
        color: #D2127E;
      }
    }
  }
}

.rowActas {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  .elemento {
    /* max-width: 140px; */
    display: inline-block;
    margin-right: 30px;
    border: 1px solid black;
    padding: 0;
    img {
      max-width: 40px;
      padding-bottom: 20px;
      padding-top: 20px;

    }

    .grilla-partidos {
      display: grid;
      background-color: #F4F4F4;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      padding: 10px;
      align-items: flex-end;
    }
  }

  /* @media (min-width: 1200px) {
    max-width: 1000px;
  }
  @media (min-width: 1400px) {
    max-width: 1200px;
  } */
}

.table-responsive {
  table {
    thead {
      tr {
        background-color: #181A2F;
        color: #FFF;

        th {
          vertical-align: middle;
          img {
            max-width: 35px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          text-align: center;
          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }
}

.encabezado {
  background-image: url('../../../assets/Fondo_Mapa.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1rem;
  margin-bottom: 1rem;
}

.proceso {
  color: #858585;
}
.terminado {
  color: #6F65A3;
}
</style>
